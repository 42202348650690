import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { withRouter, Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Button, DialogContentText, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useAppStyles } from '../../Styles';
import { useState } from "react";

function ChangeAppointmentDialog(props) {
    const classes = useAppStyles();
    const { onClose, open, episodeId, eventUUID, rescheduleURL, appointmentType } = props;
    const [cancelSelected, setCancelSelected] = useState(false);

    const handleClose = (cancelApptSuccess) => {
        setCancelSelected(false);
        onClose(cancelApptSuccess);
    };

    const history = useHistory()

    const handleReschedule = () => {
        history.push({
            pathname: `/schedule/${episodeId}`,
            state: {
                scheduleURL: rescheduleURL,
                appointmentType: appointmentType,
            }
        })
    }

    const handleCancelClick = () => {
        setCancelSelected(true)
    }

    const handleConfirmAppointmentCancel = async (event) => {
        event.preventDefault();

        try {
            const url = process.env.REACT_APP_API_URL + "/appointment/cancel"
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 'event_uuid': eventUUID, 'reason': 'reasonn??' })
            });
            if (response.ok) {
                handleClose(true)
            } else {
                console.error('Cancellation failed: ', await response.text());
            }
        } catch (error) {
            console.error('Error occured: ', error)
        };
    }
    return (
        <Dialog
            onClose={() => handleClose(false)}
            aria-labelledby="simple-dialog-title"
            open={open}
            PaperProps={{
                className: classes.dialog
            }}
        >
            {open && !cancelSelected && <>
                <DialogTitle id="simple-dialog-title">Change my appointment</DialogTitle>
                <Button
                    onClick={handleReschedule}
                >
                    Reschedule
                </Button>
                <Button
                    onClick={handleCancelClick}
                >
                    Cancel Appointment
                </Button>
            </>
            }
            {cancelSelected && <>
                <DialogTitle id="simple-dialog-title">Change my appointment</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to cancel your appointment?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmAppointmentCancel}>
                        Yes
                    </Button>
                    <Button
                        onClick={() => handleClose(false)}>
                        No
                    </Button>
                </DialogActions>
            </>}
        </Dialog>
    );
}

ChangeAppointmentDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    episodeId: PropTypes.string.isRequired,
};

export default withRouter(ChangeAppointmentDialog);