import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { withRouter } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Divider } from "@mui/material";
import CarePlan from "./CarePlan";
import NextAppointment from "./NextAppointment"
import { useAppStyles } from '../../Styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 125px)',
    },
    description: {
        fontFamily: "roboto",
        fontWeight: "700",
        paddingBottom: theme.spacing(1),
    },
    overline: {
        margin: theme.spacing(1, 0, 2),
        fontSize: '15pt',
        color: "#6a7685",
    },
    divider: {
        paddingTop: "20px",
        paddingBottom: "20px",
    },
}));

function Episode({ episode, state, language, fullname, email, scheduleURL, numVisits, shouldPollNextAppointment = false }) {
    const classes = useStyles();
    const description = episode.description
    const created = moment.parseZone(episode.createdAt).format("MMMM D, YYYY");
    const closedAt = episode.closedAt
    const closedString = moment.parseZone(episode.closedAt).format("MMMM D, YYYY");
    const episodeID = episode.id;
    const appointmentType = numVisits > 0 ? "Follow-up" : "Evaluation";
    //console.log("appointment type: " + appointmentType)

    const { hash } = useLocation();
    const [hasPlan, setHasPlan] = useState(false);
    const APPOINTMENTS = gql`
    query getAppointmentEventsByEpisode($episodeID: ID!) {
      getAppointmentEventsByEpisode(episodeID: $episodeID) {
         startTime
      }
    }
  `;

    const {
        data: appointmentData,
        appointmentLoading,
        appointmentError
    } = useQuery(APPOINTMENTS, { variables: { episodeID } });
    useEffect(() => {
        if (episode.evaluations && episode.evaluations.length > 0) {
            episode.evaluations.forEach(visit => {
                if (visit.plan) {
                    setHasPlan(true);
                }
            })
        }
    }, [episode])
    useEffect(() => {
        if (hash !== '') {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }

        if (appointmentError) {
            return `an error occured: ${appointmentError.message}`;
        }
    }, [hash,
        appointmentLoading, appointmentData, appointmentError]);

    return (
        <Grid item xs={12}>
            <Card xs={12} id={episode.id}>
                <CardContent>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <Typography variant="subtitle1" display="block" gutterBottom className={classes.overline}>
                            EPISODE
                        </Typography>
                        <Typography variant="subtitle1" display="block" gutterBottom className={classes.description}>
                            {description}
                        </Typography>
                    </Box>

                    <Typography variant={"body2"} gutterBottom>Created: {created}</Typography>
                    {closedAt && <Typography variant={"body2"} gutterBottom>Closed: {closedString}</Typography>}
                    <NextAppointment
                        closedAt={closedAt}
                        episodeId={episodeID}
                        state={state}
                        language={language}
                        fullname={fullname}
                        email={email}
                        scheduleURL={scheduleURL}
                        appointmentType={appointmentType}
                        shouldPollNextAppointment />
                    {hasPlan &&
                        <>
                            <Box className={classes.divider} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                <Divider variant="middle" />
                            </Box>
                            <Typography variant="subtitle1" display="block" gutterBottom className={classes.overline}>
                                CARE PLAN
                            </Typography>

                            {episode.evaluations.map((visit, index) => {
                                const treatments = episode.treatments.filter(treatment => visit.treatmentPathways.includes(treatment.id));
                                let key = `plan-${visit.id}-${index}`
                                return (
                                    <CarePlan
                                        key={key}
                                        plan={visit.plan}
                                        createdAt={visit.createdAt}
                                        treatments={treatments}
                                        visitId={visit.id}
                                        index={index}
                                    />
                                )
                            }
                            )
                            }
                        </>
                    }
                </CardContent>
            </Card>
        </Grid>
    )
        ;
}


export default withRouter(Episode)
