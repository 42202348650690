
export const yearValidation = {
    required: 'Required field',
    pattern: {
        value: /(?:19|20)[0-9]{2}$/,
        message: "Invalid birth year"
    }
};

export const monthValidation = {
    required: 'Required field',
};

export const dayValidation = {
    required: 'Required field',
    pattern: {
        value: /^(0?[1-9]|[012][0-9]|[3][0-1])$/,
        message: 'Invalid birth day',
    }
};

export const emailValidation = {
    required: 'Required field',
    pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Invalid email address"
    }
};

export const nameValidation = {
}

export const genderValidation = {
    required: 'Required field',
};
