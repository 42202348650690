import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select'
import { Box, MenuItem } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import { withRouter } from 'react-router-dom';
import { useForm, useController } from 'react-hook-form';
import {spanishProviderStates, states} from '../../constants/Constants';

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontSize: '15pt',
        color: '#6a7685',
    }
}));

function StateSelector({ history }) {
    const classes = useStyles();
    const {
        control,
        handleSubmit,
        formState: { errors },
        register
    } = useForm({})

    const {
        field: { value, setValue }
    } = useController({
        name: 'selectState',
        control,
        defaultValue: ''
    });

    const onSubmit = (data) => {
        const selectedState = data.selectState;
        localStorage.setItem('state', selectedState);
        if (spanishProviderStates.includes(selectedState)) {
            history.push('/eligibility/language');
        } else {
            history.push('/profile');
        }
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    let stateOptionsItems = [];
    for (const [abbrev, name] of states) {
        stateOptionsItems.push(<MenuItem value={abbrev}>{name}</MenuItem>);
    }

    return (
        <Container component="main" maxWidth="xs">
            <div>
                <h1>What state do you live in?</h1>
                <p
                    className={classes.subtitle}>
                    Please make sure you're located in the same state during the initial visit.
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputLabel id="selectState">State</InputLabel>
                    <Select
                        labelId="selectState"
                        id="selectState"
                        name="selectState"
                        label="State"
                        onChange={handleChange}
                        value={value}
                        displayEmpty
                        variant="outlined"
                        fullWidth
                        {...register('selectState', {
                            required: "Select a state."
                        })}
                    >
                        <MenuItem value="" disabled>
                            Select a state
                        </MenuItem>
                        {stateOptionsItems}
                    </Select>
                    {errors.selectState && (
                        <p style={{ color: "red" }}> {errors.selectState.message}</p>
                    )}
                    <Box
                        marginTop={2}
                    >
                        <Button
                            style={{
                                backgroundColor: "#5b92e5",
                            }}
                            className={classes.continueButton} color="primary" variant="contained" size="medium" type="submit">Continue</Button>
                    </Box>
                </form>
            </div>
        </Container>
    )

}

export default withRouter(StateSelector)
