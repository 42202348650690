
import React from 'react';
import { withRouter } from "react-router-dom";
import StateSelector from './StateSelector';

function Eligibility() {
    return (<StateSelector />);
}

export default withRouter(Eligibility)
