import React, {useEffect, useState} from 'react'
import {useForm} from "react-hook-form";
import {withRouter} from "react-router-dom";
import Container from "@material-ui/core/Container";
import ButtonAppBar from "../../components/NavBar";
import {gql, useMutation, useQuery} from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
    dayValidation,
    emailValidation,
    monthValidation,
    nameValidation,
    yearValidation
} from "../../components/forms/validations";
import {monthMenuItems} from "../../components/forms/SelectMenuItems";
import {FormTextFieldComponent} from "../../components/forms/FormTextFieldComponent";
import {FormSelectComponent} from "../../components/forms/FormSelectFieldComponent";
import {Alert, Snackbar} from "@mui/material";
import {CircularProgressBar} from "../../components/progressbar/CircularProgressBar";

function EditProfile() {

    const {
        handleSubmit,
        formState: {errors},
        register,
        reset,
        getValues,
        watch,
    } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            firstName: "",
            lastName: "",
            birthYear: "",
            birthMonth: 0,
            birthDay: "",
            email: "",
        }
    });

    const EDIT_PROFILE = gql`
      mutation UpdateProfile($firstname: String!, $lastname: String!, $email: String!, $birthDate: String!, $gender: Int!, $state: String!, $language: String!) {
        updateProfile(input: {
            firstname: $firstname,
            lastname: $lastname,
            email: $email,
            birthDate: $birthDate,
            gender: $gender,
            state: $state,
            language: $language,
        }) {
            id
            firstname
            lastname
            email
            isEnrolled
        }
      }
    `;

    const [updateProfile, {data: updateData, loading: updateLoading, error: updateError}] = useMutation(EDIT_PROFILE);
    const [open, setOpen] = useState(false);
    const [updating, setUpdating] = useState(false);

    const MEQ = gql`
        {
            me {
                firstname
                lastname
                birthDate
                email
                phone
                gender
                state
                language
            }
        }
    `;
    const onSubmit = () => {
        const firstName = getValues('firstName').trim().replace(/\s{2,}/g, ' ')
        const lastName = getValues('lastName').trim().replace(/\s{2,}/g, ' ')
        const newBirthDate = new Date(
            getValues('birthYear'),
            getValues('birthMonth'),
            getValues('birthDay')
        )
        const email = getValues('email').replace(/\s/g, '')
        const gender = getValues('gender')
        const state = getValues('state')
        const language = getValues('language')
        updateProfile({
            variables: {
                firstname: firstName,
                lastname: lastName,
                birthDate: newBirthDate.toISOString().split("T")[0],
                email: email,
                gender: gender,
                state: state,
                language: language,
            }
        })
    }
    const {data: userData, userFetchError, loading: userFetchLoading} = useQuery(MEQ);

    useEffect(() => {
        if (userData) {
            const [year, month, day] = userData.me.birthDate.split("-");
            reset({
                firstName: userData.me.firstname,
                lastName: userData.me.lastname,
                birthMonth: parseInt(month) - 1,
                birthDay: day,
                birthYear: year,
                email: userData.me.email,
                gender: userData.me.gender,
                state: userData.me.state,
                language: userData.me.language,
            });
        }
    }, [reset, userData]);

    useEffect(() => {
        if (updateData) {
            setOpen(true)
            setUpdating(false)
            return
        }
        if (updateLoading) {
            setUpdating(true)
            return
        }
        if (updateError) {
            setUpdating(false)
            return
        }
        if (userData) {
            setUpdating(false)
            return
        }
        if (userFetchLoading) {
            setUpdating(true)
            return
        }
        if (userFetchError) {
            setUpdating(false)
            return `an error occurred: ${userFetchError.message}`;
        }
    }, [updateData, updateLoading, updateError, userData, userFetchLoading, userFetchError])

    const watchAllFields = watch();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <ButtonAppBar/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container component="main" maxWidth="sm">
                    <h1>My Settings</h1>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                            {FormTextFieldComponent(
                                watchAllFields.firstName,
                                "firstName",
                                "First Name",
                                errors.firstName,
                                register,
                                nameValidation,
                            )}
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            {FormTextFieldComponent(
                                watchAllFields.lastName,
                                "lastName",
                                "Last Name",
                                errors.lastName,
                                register,
                                nameValidation,
                            )}
                        </Grid>
                        <Grid item xs={5}>
                            {FormSelectComponent(
                                watchAllFields.birthMonth,
                                "birthMonth",
                                "Birth Month",
                                errors.birthMonth,
                                register,
                                monthValidation,
                                monthMenuItems,
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            {FormTextFieldComponent(
                                watchAllFields.birthDay,
                                "birthDay",
                                "Birth Day",
                                errors.birthDay,
                                register,
                                dayValidation,
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            {FormTextFieldComponent(
                                watchAllFields.birthYear,
                                "birthYear",
                                "Birth Year",
                                errors.birthYear,
                                register,
                                yearValidation,
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {FormTextFieldComponent(
                                watchAllFields.email,
                                "email",
                                "Email",
                                errors.email,
                                register,
                                emailValidation,
                            )}
                        </Grid>
                        <Grid item xs={12} style={{width: '100%'}}>
                            <Button type="submit" fullWidth variant="contained" color="primary">
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" disabled={updating} sx={{width: '100%'}}>
                    Update Successful!
                </Alert>
            </Snackbar>
            {CircularProgressBar(updating)}
        </>
    )
}

export default withRouter(EditProfile)
