import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
  Observable,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const apiUrl = process.env.REACT_APP_API_URL;
const httpLink = new HttpLink({ uri: apiUrl + "/graphql" });

function getCookie(cookieName) {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const [name, value] = cookie.trim().split('=');
    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }
  return null;
}

function setCookie(cookieName, cookieValue, expiration) {
    document.cookie = `${cookieName}=${encodeURIComponent(cookieValue)}; ${expiration}; path=/`;
}

export function setAccessToken(cookieValue){
  const expirationTime = new Date(Date.now() + (1 * 60 * 60 * 1000) + (5 * 60 * 1000)); // 1 hour and 5 minutes from now
  const expires = "expires=" + expirationTime.toUTCString();
  setCookie('accessToken', cookieValue, expires);
}

export function setRefreshToken(cookieValue){
  const expirationTime = new Date(Date.now() + (1 * 30 * 24 * 60 * 60 * 1000) + (5 * 60 * 1000)); // 1 month and 5 minutes from now
  const expires = "expires=" + expirationTime.toUTCString();
  setCookie('refreshToken', cookieValue, expires);
}

const authLink = setContext((_, {headers, ...context}) => {
    const token = getCookie('accessToken');
    return {
      headers: {
        ...headers,
        ...(token ? {authorization: `Bearer ${token}`} : {}),
      },
      ...context,
    };
});


// Observable-based tokenRefresh
const tokenRefreshObservable = new Observable((subscriber) => {
  const tokenRefresh = async () => {
    try {
      const refresh = getCookie('refreshToken');
      const res = await fetch(apiUrl + '/validate/refresh', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: refresh }),
      });

      if (!res.ok) {
        console.log('Token refresh failed');
      } else {
        const response = await res.json();
        const newAccess = response.access
        const newRefresh = response.refresh

        setAccessToken(newAccess);
        setRefreshToken(newRefresh);

        return response.access;
      }
    } catch (error) {
      console.log('Token refresh error: ', error);
      subscriber.error(error);
    }
  };

  tokenRefresh().then((access) => {
    subscriber.next(access);
    subscriber.complete();
  });
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );

  if (networkError && networkError.statusCode === 401) {
    console.log('Received 401 Unauthorized response from the server');
    return tokenRefreshObservable.flatMap((access) => {
      const oldHeaders = operation.getContext().headers;
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: `Bearer ${access}`,
        },
      });
      return forward(operation);
    });
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink, httpLink])
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
