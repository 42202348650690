import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withRouter, useLocation } from "react-router-dom";
import Episode from "./episode/Episode"
import { gql, useQuery, useMutation } from '@apollo/client';
import ButtonAppBar from './NavBar';
import TextField from '@material-ui/core/TextField';
import "@fontsource/roboto";
import "@fontsource/roboto/700.css";
import { makeStyles } from '@material-ui/core/styles';
import { hideClosedEpisodes, showClosedEpisodesButton } from "../constants/Strings";
import { Alert, Snackbar } from "@mui/material";
import { CircularProgressBar } from "./progressbar/CircularProgressBar";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 125px)',
    },
    header: {
        fontFamily: "roboto",
        fontWeight: "700",
        paddingBottom: theme.spacing(1),
    },
    instructions: {
        margin: theme.spacing(1, 0, 2),
        fontSize: '15pt',
        color: "#6a7685",
    },
    continueButton: {
        margin: theme.spacing(1, 0, 1),
        float: 'right',
    },
}));

function SignedInHome(props) {

    const classes = useStyles();

    const [value, setValue] = useState('');
    const [showClosedEpisodes, setShowClosedEpisodes] = useState(false)
    const [showClosedEpisodesButtonText, setShowClosedEpisodesButtonText] = useState(showClosedEpisodes ? hideClosedEpisodes : showClosedEpisodesButton)
    const [showClosedEpisodesButtonIsVisible, setShowClosedEpisodesButtonIsVisible] = useState(false);
    const [showProgressBar, setShowProgressbar] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [fullname, setFullname] = useState("")
    const [state, setState] = useState("")
    const [language, setLanguage] = useState("en")
    const [email, setEmail] = useState("")
    const location = useLocation()
    const shouldPollNextAppointment = location.state?.shouldPollNextAppointment ? location.state?.shouldPollNextAppointment : false
    //console.log("signedinhome scheduled appointment:" + shouldPollNextAppointment)


    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false);
    };

    const showClosedEpisodesHandler = () => {
        setShowClosedEpisodes(!showClosedEpisodes)
        showClosedEpisodes ? setShowClosedEpisodesButtonText(showClosedEpisodesButton) : setShowClosedEpisodesButtonText(hideClosedEpisodes)
    }

    const CREATE_EPISODE = gql`
      mutation CreateEpisode($description: String!) {
        createEpisode(input: {
          description: $description
        }) {
          id
          scheduleURL
        }
      }
  `;
    const [newEpisode, {
        data: newEpisodeData,
        newEpisodeLoading,
        newEpisodeError
    }, error] = useMutation(CREATE_EPISODE, {
        onError: (error) => {
            setCreateEpisodeError(error.message);
        }
    });

    const [createEpisodeError, setCreateEpisodeError] = useState("")


    const episodeHandler = (e) => {
        e.preventDefault()

        newEpisode({
            variables: {
                description: value
            }
        })
    }

    // Episode creation state variables.
    if (newEpisodeError) {
        console.log("error occurred");
    }

    if (newEpisodeLoading) {
        console.log("loading");
    }

    if (newEpisodeData) {
        props.history.push({
            pathname: "/schedule/" + newEpisodeData.createEpisode.id,
            state: {
                scheduleURL: newEpisodeData.createEpisode.scheduleURL,
            }
        })
    }

    const ME = gql`
    {
      me {
        isEnrolled
        firstname
        lastname
        state
        language
      }
    }
  `;

    const OPEN_EPISODES = gql`
    {
      getOpenUserEpisodes {
        id
        description
        createdAt
        closedAt
        scheduleURL
        trends {
          name
          x
          y
        }
        evaluations {
          id
          createdAt
          plan
          treatmentPathways
        }
        appointments
        treatments {
          id
          region
          name
          videos {
            id
            url
          }
        }
      }
    }
  `;

    const CLOSED_EPISODES = gql`
    {
      getClosedUserEpisodes {
        id
        description
        createdAt
        closedAt
        trends {
          name
          x
          y
        }
        evaluations {
          id
          createdAt
          plan
          treatmentPathways
        }
        appointments
        treatments {
          id
          region
          name
          videos {
            id
            url
          }
        }
      }
    }
  `;

    const { data: userData, loading: userLoading, error: userError } = useQuery(ME);
    const { data: openEpisodesData, loading: openEpisodesLoading, error: openEpisodesError } = useQuery(OPEN_EPISODES);
    const {
        data: closedEpisodesData,
        loading: closedEpisodesLoading,
        error: closedEpisodesError
    } = useQuery(CLOSED_EPISODES);

    useEffect(() => {
        if (userLoading || openEpisodesLoading || closedEpisodesLoading) {
            setShowProgressbar(true)
        } else if (!userLoading && !openEpisodesLoading && !closedEpisodesLoading) {
            setShowProgressbar(false)
        }
        if (userData && openEpisodesData && closedEpisodesData) {
            setShowProgressbar(false)
            if (closedEpisodesData) {
                setShowClosedEpisodesButtonIsVisible(closedEpisodesData.getClosedUserEpisodes.length > 0)
            }
        }
        if (userError || openEpisodesError || closedEpisodesError) {
            setShowSnackbar(true)
        }
        if (userData && userData.me) {
            setState(userData.me.state)
            setLanguage(userData.me.language)
            setFullname(userData.me.fullname)
        }
    }, [
        userLoading, openEpisodesLoading, closedEpisodesLoading,
        userData, openEpisodesData, closedEpisodesData,
        userError, openEpisodesError, closedEpisodesError,
    ])

    if (userError) return `an error occurred: ${userError.message}`
    if (openEpisodesError) return `an error occurred: ${openEpisodesError.message}`


    return (
        <>
            <ButtonAppBar shouldPollNextAppointment={false} />
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                        </Grid>

                        {(() => {
                            if (userData) {
                                return (
                                    <Grid item xs={12} sm={12}>
                                        <h1>Welcome to Aware Health</h1>

                                        <div className={classes.instructions}>
                                            Which symptoms or reasons are you seeking care for, {userData.me.firstname}?
                                        </div>
                                        <TextField
                                            id="outlined-multiline-flexible"
                                            label="Describe symptoms"
                                            multiline
                                            minRows={2}
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            defaultValue=""
                                            onChange={handleChange}
                                            helperText={createEpisodeError}
                                            FormHelperTextProps={{ style: { color: 'red' } }}
                                        />
                                        <Button
                                            style={{
                                                backgroundColor: "#5b92e5",
                                            }}
                                            className={classes.continueButton} color="primary" variant="contained"
                                            size="medium" onClick={episodeHandler}>Log a new problem</Button>
                                    </Grid>
                                )
                            }
                        })()}

                        {openEpisodesData && openEpisodesData.getOpenUserEpisodes.map((episode) => {
                            return <Episode
                                key={episode.id}
                                episode={episode}
                                state={state}
                                language={language}
                                fullname={fullname}
                                email={email}
                                scheduleURL={episode.scheduleURL}
                                numVisits={episode.evaluations.length}
                            />;
                        })}
                        <Grid item xs={12} sm={12}>

                            {showClosedEpisodesButtonIsVisible &&
                                <Button
                                    style={{ backgroundColor: "#5b92e5" }}
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    href={""}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={showClosedEpisodesHandler}
                                >
                                    {showClosedEpisodesButtonText}
                                </Button>
                            }

                        </Grid>
                        {showClosedEpisodes && closedEpisodesData && closedEpisodesData.getClosedUserEpisodes.map((episode) => (
                            <Episode
                                key={episode.id}
                                episode={episode}
                                showClosedEpisode={true}
                                shouldPollNextAppointment={shouldPollNextAppointment} />
                        ))}

                    </Grid>
                </div>
            </Container>
            <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" disabled={showProgressBar} sx={{ width: '100%' }}>
                    Error retrieving data.
                </Alert>
            </Snackbar>
            {CircularProgressBar(showProgressBar)}
        </>
    )
}

export default withRouter(SignedInHome)
