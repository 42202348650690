import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select'
import { Box } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useForm, useController } from 'react-hook-form';

const useStyles = makeStyles();
var languageMap = new Map();
languageMap.set('en', 'English');
languageMap.set('es', 'Español');
const defaultLanguage = 'en';
localStorage.setItem('language', defaultLanguage);

function LanguageSelector({ history }) {
    const classes = useStyles();
    const {
        control,
        handleSubmit,
        formState: { errors },
        register,
    } = useForm({})

    const {
        field: { value, setValue }
    } = useController({
        name: 'selectLanguage',
        control,
        defaultValue: defaultLanguage,
    })

    const onSubmit = (data) => {
        const selectedLanguage = data.selectLanguage;
        localStorage.setItem('language', selectedLanguage);
        history.push('/profile');
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    let langOptions = [...languageMap.keys()].map((key) =>
        <MenuItem value={key}>{languageMap.get(key)}</MenuItem>
    )

    return (
        <Container component="main" maxWidth="xs">
            <div>
                <h1>Select your preferred language for your visit.</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputLabel id="selectLanguage">Language</InputLabel>
                    <Select
                        fullWidth
                        labelId="selectLanguage"
                        id="selectLanguage"
                        label="Language"
                        onChange={handleChange}
                        variant="outlined"
                        defaultValue={defaultLanguage}
                        {...register('selectLanguage', {
                            required: "Select a language."
                        })}
                    >
                        {langOptions}
                    </Select>
                    <Box marginTop={2}>
                        <Button
                            style={{
                                backgroundColor: "#5b92e5",
                            }}
                            className={classes.continueButton} color="primary" variant="contained" size="medium" type="submit">Continue</Button>
                    </Box>
                </form>
            </div>
        </Container>
    )

}

export default withRouter(LanguageSelector)
