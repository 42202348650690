import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import "@fontsource/roboto";
import "@fontsource/roboto/700.css";
import {setAccessToken, setRefreshToken} from "../index"

import {withRouter} from "react-router-dom";

import {gql, useMutation} from '@apollo/client';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 150px)',
    },
    header: {
        fontFamily: "roboto",
        fontWeight: "700",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(0),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
    instructions: {
        margin: theme.spacing(0, 0, 2),
        fontSize: '15pt',
        color: "#6a7685",
    },
}));

function Verify(props) {
    const mobile = localStorage.getItem('phone');
    const classes = useStyles();

    const initialFormValue = {
        verification: ''
    }
    const [formValue, setFormValue] = useState(initialFormValue)

    const updateForm = (inputName, inputValue) => {
        setFormValue({
            ...formValue,
            [inputName]: inputValue
        })
    }

    const onChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        updateForm(name, value)
    }

    const VERIFY = gql`
      mutation Verify($nonce: String!, $code: String!) {
        verify(nonce: $nonce, code: $code) {
          id
          firstname
          lastname
          email
          accessToken
          refreshToken
          isEnrolled
          state
          language
        }
      }
    `;

    const [verify, {data, loading, error}] = useMutation(VERIFY);

    if (loading) return '';
    if (error) return `Submission error! ${error.message}`

    const handleSubmit = (e) => {
        e.preventDefault()
        verify({variables: {nonce: localStorage.getItem('nonce'), code: formValue.verification}})
    }

    if (data) {
        localStorage.removeItem('nonce');
        localStorage.removeItem('phone');
        localStorage.setItem('id', data.verify.id);
        setAccessToken(data.verify.accessToken)
        setRefreshToken(data.verify.refreshToken)
        localStorage.setItem('isEnrolled', data.verify.isEnrolled);
        localStorage.setItem('state', data.verify.state);
        localStorage.setItem('language', data.verify.language);

        // If we don't have a firstname, push the user to complete
        // the profile information. Otherwise, push to main dashboard
        // unless they haven't enrolled yet.
        if (data.verify.firstname == null) {
            props.history.push('/eligibility')
        } else {
            localStorage.setItem('firstname', data.verify.firstname)
            localStorage.setItem('lastname', data.verify.lastname)
            localStorage.setItem('email', data.verify.email)

            props.history.push('/')
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <h1>Please enter the code sent to {mobile}</h1>
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    <NumberFormat
                        type="tel"
                        label="Confirmation code"
                        style={{width: '100%'}}
                        variant="outlined"
                        customInput={TextField}
                        format="####"
                        mask="_"
                        name="verification"
                        value={formValue.verification}
                        onChange={onChange}/>
                    <div>
                        <p>Not receiving a code? Click <a href="/support">here</a> to get support.</p>
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            backgroundColor: "#5b92e5",
                        }}
                        className={classes.submit}
                    >
                        Next
                    </Button>
                </form>
            </div>
            <Footer/>
        </Container>
    )
}

export default withRouter(Verify)
