import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";

import Signin from "./components/Signin"
import Verify from "./components/Verify"
import SignedInHome from "./components/SignedInHome";
import Enroll from "./components/Enroll";
import ProfileInfo from "./components/ProfileInfo";
import Intake from "./components/Intake";
import Calendly from "./components/Calendly";
import Terms from "./components/Terms";
import ReactGA from 'react-ga';
import Eligibility from "./features/eligibility";
import LanguageSelector from "./features/eligibility/LanguageSelector"
import EditProfile from "./features/settings/EditProfile";
import Support from "./features/support/Support";
import { ThemeProvider, createTheme } from "@material-ui/core";

const theme = createTheme();
const TRACKING_ID = "G-WVKMCQYZJ6";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/verify">
            <Verify />
          </Route>
          <Route path="/support">
            <Support />
          </Route>
          <Route path="/signin/:id?">
            <Signin />
          </Route>
          <Route path="/profile">
            <ProfileInfo />
          </Route>
          <Route path="/tos">
            <Terms />
          </Route>
          <Route path="/terms-of-service">
            <Terms />
          </Route>
          <PrivateRoute exact path="/" component={SignedInHome} />
          <PrivateRoute exact path="/care" component={Intake} />
          <PrivateRoute exact path="/enroll" component={Enroll} />
          <PrivateRoute exact path="/schedule/:id" component={Calendly} />
          <PrivateRoute exact path="/eligibility" component={Eligibility} />
          <PrivateRoute exact path="/eligibility/language" component={LanguageSelector} />
          <PrivateRoute exact path="/settings/profile" component={EditProfile} />
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

export default App;
