import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {FormHelperText} from "@material-ui/core";
import React from "react";
export const FormSelectComponent = (value, fieldName, label, error, register, validation, menuItems) => {
    return (
        <FormControl
            fullWidth
            error={error}>
            <InputLabel id={fieldName.concat("SelectLabel")}>{label}</InputLabel>

            <Select
                style={{width: '100%'}}
                label={label}
                name={fieldName}
                variant="outlined"
                {...register(fieldName, validation)}
                value={value}
            >
                {menuItems}
            </Select>
            <FormHelperText
                error={error}
                variant="outlined">{error && error.message}</FormHelperText>
        </FormControl>
    )
}
