function Support() {
    return (
        <div>
            <p>
                Please email us at support@awarehealth.io for assistance.
            </p>
        </div>
    )
}

export default Support