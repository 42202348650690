import React from 'react'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import "@fontsource/roboto";
import "@fontsource/roboto/700.css";
import { withRouter } from "react-router-dom";
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {FormTextFieldComponent} from "./forms/FormTextFieldComponent";
import {
    dayValidation,
    emailValidation,
    genderValidation,
    monthValidation,
    nameValidation,
    yearValidation
} from "./forms/validations";
import {FormSelectComponent} from "./forms/FormSelectFieldComponent";
import {genderMenuItems, monthMenuItems} from "./forms/SelectMenuItems";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 150px)',
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
    instructions: {
        margin: theme.spacing(0, 0, 2),
        fontSize: '15pt',
        color: "#6a7685",
    },
}));

function ProfileInfo(props) {
    const classes = useStyles();
    const {
        handleSubmit,
        formState: { errors },
        register,
        getValues,
        watch,
    } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            firstName: '',
            lastName: '',
            birthMonth: '',
            birthDay: '',
            birthYear: '',
            email: '',
            gender: '',
        }
    })

    const onSubmit = () => {
        const firstName = watchAllFields.firstName.trim().replace(/\s{2,}/g, ' ')
        const lastName = watchAllFields.lastName.trim().replace(/\s{2,}/g, ' ')
        const newBirthDate = new Date(
            watchAllFields.birthYear,
            getValues("birthMonth"),
            watchAllFields.birthDay,
        )
        const email = watchAllFields.email.replace(/\s/g, '')
        const gender = getValues("gender");
        const stateData = localStorage.getItem('state');
        const langData = localStorage.getItem('language');

        updateProfile({
            variables: {
                firstname: firstName,
                lastname: lastName,
                birthDate: newBirthDate.toISOString().split("T")[0],
                email: email,
                gender: gender,
                state: stateData,
                language: langData,
            }
        })
    }

    const UPDATE_PROFILE = gql`
      mutation UpdateProfile($firstname: String!, $lastname: String!, $email: String!, $birthDate: String!, $gender: Int!, $state: String!, $language: String!) {
        updateProfile(input: {
            firstname: $firstname,
            lastname: $lastname,
            email: $email,
            birthDate: $birthDate,
            gender: $gender,
            state: $state,
            language: $language,
        }) {
            id
            firstname
            lastname
            gender
            email
            isEnrolled
        }
      }
    `;

    const [updateProfile, { data, error }] = useMutation(UPDATE_PROFILE);

    if (error) {
        console.log("error occurred");
    }

    if (data) {
        localStorage.setItem('firstname', data.updateProfile.firstname);
        localStorage.setItem('lastname', data.updateProfile.lastname);
        localStorage.setItem('email', data.updateProfile.email);
        localStorage.setItem('sex', data.updateProfile.gender);

        props.history.push('/')
    }
    const watchAllFields = watch();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Container component="main" maxWidth="sm">
                <div className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <h1>Tell us a bit about you</h1>
                            <div className={classes.instructions}>
                                This will help us personalize your care.
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            {FormTextFieldComponent(
                                watchAllFields.firstName,
                                "firstName",
                                "First Name",
                                errors.firstName,
                                register,
                                nameValidation,
                            )}
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            {FormTextFieldComponent(
                                watchAllFields.lastName,
                                "lastName",
                                "Last Name",
                                errors.lastName,
                                register,
                                nameValidation,
                            )}
                        </Grid>
                        <Grid item xs={5}>
                            {FormSelectComponent(
                                watchAllFields.birthMonth,
                                "birthMonth",
                                "Birth Month",
                                errors.birthMonth,
                                register,
                                monthValidation,
                                monthMenuItems,
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            {FormTextFieldComponent(
                                watchAllFields.birthDay,
                                "birthDay",
                                "Birth Day",
                                errors.birthDay,
                                register,
                                dayValidation,
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            {FormTextFieldComponent(
                                watchAllFields.birthYear,
                                "birthYear",
                                "Birth Year",
                                errors.birthYear,
                                register,
                                yearValidation,
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {FormTextFieldComponent(
                                watchAllFields.email,
                                "email",
                                "Email",
                                errors.email,
                                register,
                                emailValidation,
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {FormSelectComponent(
                                watchAllFields.gender,
                                "gender",
                                "Gender",
                                errors.gender,
                                register,
                                genderValidation,
                                genderMenuItems,
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ width: '100%' }}>
                            <Button type="submit" fullWidth variant="contained" color="primary">
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </form>
    )
}

export default withRouter(ProfileInfo)
