import React from 'react'
import { Widget } from '@typeform/embed-react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    enroll: {
      width: '100%',
      height: 'calc(100vh - 155px)',
    },
  }));

function Enroll() {
    const classes = useStyles();
    const userID = localStorage.getItem("id");

    return (
        <Widget className={classes.enroll} id="aw7lTkr4" hidden={{source: userID}} />
    )
}

export default Enroll
