import { gql, useQuery } from "@apollo/client";
import Button from "@material-ui/core/Button";
import { Box, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useState } from "react";
import { useHistory, withRouter } from 'react-router-dom/cjs/react-router-dom';
import { defaultBlue, defaultGreen, useAppStyles } from '../../Styles';
import ChangeAppointmentDialog from "./ChangeAppointmentDialog";

function NextAppointment({ closedAt, episodeId, state, language, fullname, email, scheduleURL, appointmentType, shouldPollNextAppointment = false }) {
    const history = useHistory()
    const classes = useAppStyles()
    const [hasNextAppointment, setHasNextAppointment] = useState(false);
    const [hasAppointmentLocation, setHasAppointmentLocation] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false)
    const [eventUUID, setEventUUID] = useState("")
    const urlPostfix = `?hide_landing_page_details=1&hide_event_type_details=1&hide_gdpr_banner=1`;
    const [rescheduleURL, setRescheduleURL] = useState("")
    const [pollNextAppointment, setPollNextAppointment] = useState(shouldPollNextAppointment)
    const [cancelApptSuccess, setCancelApptSuccess] = useState(false)
    const handleOpenDialog = () => {
        setDialogOpen(true)
    }
    const handleCloseDialog = (cancelSuccess) => {
        setDialogOpen(false)
        if (cancelSuccess) {
            setCancelApptSuccess(true)
            setHasNextAppointment(false)
            setHasAppointmentLocation(false)
            setPollNextAppointment(true)
        }
    }
    const handleScheduleAppointment = () => {
        console.log("nextappointment: " + scheduleURL)
        history.push({
            pathname: `/schedule/${episodeId}`,
            state: {
                scheduleURL: scheduleURL,
                appointmentType: appointmentType
            }
        })
    }

    const NEXT_APPOINTMENT = gql`
    query getNextAppointment($episodeId: ID!) {
        getNextAppointment(episodeID: $episodeId) {
            startTime
            location {
                joinUrl
            }
            event
            eventID
            rescheduleURL
        }
    }
    `;
    useEffect(() => {
        refetchNextAppointmentData()
    })

    const {
        data: nextAppointmentData,
        nextAppointmentLoading,
        nextAppointmentError,
        refetch: refetchNextAppointmentData,
    } = useQuery(NEXT_APPOINTMENT, {
        variables: { episodeId },
        pollInterval: pollNextAppointment ? 10000 : 0
    });

    useEffect(() => {
        if (nextAppointmentLoading) {
            // progress bar
        }
        if (nextAppointmentData) {
            if (!nextAppointmentData.getNextAppointment) {
                if (cancelApptSuccess) {
                    setPollNextAppointment(false)
                    setHasNextAppointment(false)
                    setHasAppointmentLocation(false)
                }
            }
        }

        if (nextAppointmentData && nextAppointmentData.getNextAppointment) {
            setHasNextAppointment(true)
            if (nextAppointmentData.getNextAppointment.location && nextAppointmentData.getNextAppointment.location.joinUrl) {
                setHasAppointmentLocation(true);
                setRescheduleURL(nextAppointmentData.getNextAppointment.rescheduleURL + urlPostfix)
            }
            setEventUUID(nextAppointmentData.getNextAppointment.eventID)
            if (shouldPollNextAppointment) {
                //console.log("next appt: should poll appointment: current is : " + JSON.stringify(nextAppointmentData.getNextAppointment))
            }
        }
        if (nextAppointmentError) {
            return `an error occured: ${nextAppointmentError.message}`;
        }
    }, [nextAppointmentLoading, nextAppointmentData, nextAppointmentError]);
    const options = {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZoneName: "short",
    };

    let formattedAppointment = "";
    if (hasNextAppointment) {
        const apptDateTime = new Date(nextAppointmentData.getNextAppointment.startTime);
        formattedAppointment = apptDateTime.toLocaleDateString("en-US", options)
    }
    let joinUrl = "";
    if (hasAppointmentLocation) {
        joinUrl = nextAppointmentData.getNextAppointment.location.joinUrl.toString();
    }

    if (closedAt === null) {
        return (
            <>
                <Box className={classes.divider} sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <Divider variant="middle" />
                </Box>
                <Typography variant="subtitle1" display="block" gutterBottom className={classes.overline}>
                    NEXT APPOINTMENT
                </Typography>
                {hasNextAppointment &&
                    <Typography variant="subtitle1" display="block" gutterBottom className={classes.overline}
                        fontWeight={700}>
                        {formattedAppointment}
                    </Typography>
                }
                <div>
                    {!hasNextAppointment &&
                        <Typography variant="subtitle1" display="block" gutterBottom className={classes.overline}
                            fontWeight={700}>
                            This episode does not have an upcoming appointment.
                        </Typography>
                    }
                    {!hasNextAppointment &&
                        <Button
                            style={{ backgroundColor: "#5b92e5" }}
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={handleScheduleAppointment}
                            rel="noreferrer"
                        >
                            Schedule appointment
                        </Button>
                    }
                    {hasNextAppointment && hasAppointmentLocation &&
                        <Button
                            style={{ backgroundColor: defaultGreen }}
                            variant="contained"
                            size="medium"
                            color="primary"
                            href={joinUrl}
                            target="_blank"
                            rel="noreferrer">
                            Join appointment
                        </Button>
                    }
                </div>
                <div className={classes.buttonContainer}>
                    {hasNextAppointment &&
                        <div>
                            <Button
                                style={{ backgroundColor: defaultBlue }}
                                variant="contained"
                                size="medium"
                                color="primary"
                                rel="noreferrer"
                                onClick={handleOpenDialog}>
                                Change appointment
                            </Button>
                            <ChangeAppointmentDialog
                                open={dialogOpen}
                                onClose={handleCloseDialog}
                                episodeId={episodeId}
                                eventUUID={eventUUID}
                                rescheduleURL={rescheduleURL}
                                appointmentType={appointmentType}
                            />
                        </div>
                    }
                </div>
            </>
        )
    }
    else {
        return (<></>);
    }
}

export default withRouter(NextAppointment)
