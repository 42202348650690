import React from 'react';
import { Widget } from '@typeform/embed-react'
import { makeStyles } from '@material-ui/core/styles';
import { gql, useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
    intake: {
        width: '100%',
        height: 'calc(100vh - 155px)',
    },
}));

function Intake() {

    const classes = useStyles();
    // const userID = localStorage.getItem("id");
    // const firstname = localStorage.getItem("firstname");

    const ME = gql`
    {
        me {
            id
            gender
            firstname
        }
    }`;

    const { data, loading, error } = useQuery(ME);

    if (loading) return "";
    if (error) return `an error occurred: ${error.message}`

    let userID = ""
    let firstname = ""
    let gender = "male";

    if (data){
        userID = data.me.id
        firstname = data.me.firstname

        const sex = data.me.gender;
        if (sex === 2) {
            gender = "female"
        }
    }

    return (
        <Widget id="EEtyqb0Y" hidden={{name:firstname, source: userID, gender: gender}} className={classes.intake} />
    )
}

export default Intake
