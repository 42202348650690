import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  footer: {
    margin: '0px 0 10px',
    color: '#757575',
    textAlign: 'center',
    width: '100%',
    fontSize: '12px'
  },
  bold: {
    fontWeight: 'bold'
  },
  foot: {
    marginTop: '0rem',
    padding: '0rem',
    bottom: 0,
    left: 0,
    width: '100%',
    position: 'absolute'
  }
}))

function Footer (props) {
  const classes = useStyles()
  return (
    <footer className={classes.foot}>
      <p className={classes.footer}>
        <span className={classes.bold}>Aware Health</span>
      </p>
    </footer>
  )
}

export default Footer
