import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useLocation } from "react-router-dom";
import ButtonAppBar from './NavBar';

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
    instructions: {
        margin: theme.spacing(0, 0, 2),
        fontSize: '15pt',
        color: "#6a7685",
    },
}));

// Function to check if the event is from Calendly
function isCalendlyEvent(e) {
    return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
};


function Calendly() {
    const classes = useStyles();

    // NOTE: Probably a better way to do all this mess. But it 
    // works for now.
    // The original calendly group that Heidi deleted.
    // https://calendly.com/d/chg-xsz-nz5/orthopedic-evaluation
    const location = useLocation()
    const [hasScheduledAppointment, setHasScheduledAppointment] = useState(false)

    const url = location?.state?.scheduleURL
    const appointmentType = location?.state?.appointmentType;
    const evalDescription = "Find a time that is convenient for you and book your initial evaluation with an Aware Health Orthopedic Clinical Specialist."
    const followupDescription = "Find a time that is convenient for you and book a follow-up with your Aware Health clinician."
    const appointmentDescription = appointmentType === "Evaluation" ? evalDescription : followupDescription
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });

        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute(
            'src',
            'https://assets.calendly.com/assets/external/widget.js'
        );
        head.appendChild(script);

        // leaving comments to debug window messaging. unable to implement for rescheduling due to calendly bug
        const handleMessage = (e) => {
            if (isCalendlyEvent(e)) {
                // Log the event name
                //console.log("Event name:", e.data.event);

                // Log the event details
                //console.log("Event details:", e.data.payload);

                // Check if the event is "calendly.event_scheduled"
                if (e.data.event === "calendly.event_scheduled") {
                    //console.log("Calendly scehduled appointment. setting polling to ture")
                    setHasScheduledAppointment(true)
                }
                else {
                    //console.log("Calendly other type of window notification: " + e.data.event)
                }
            }
            else {
                //console.log("other event:  " + JSON.stringify(e))
            }
        };
        window.addEventListener("message", handleMessage);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);



    return (
        <>
        {/* {console.log("before calling buttonappbar, hasscheduledappointment = " + hasScheduledAppointment)} */}
            <ButtonAppBar shouldPollNextAppointment={hasScheduledAppointment} />
            <Container component="main" maxWidth="sm">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <h1>Schedule {appointmentType}</h1>
                        <div className={classes.instructions}>
                            {appointmentDescription}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <div style={{ height: "130vh", width: "100%" }}>
                            <div className="calendly-inline-widget" data-url={url} style={{ height: "100%", width: "100%" }} />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Calendly;
