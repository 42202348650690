import { makeStyles } from '@material-ui/core/styles';

export const useAppStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 125px)',
    },
    description: {
        fontFamily: "roboto",
        fontWeight: "700",
        paddingBottom: theme.spacing(1),
    },
    overline: {
        margin: theme.spacing(1, 0, 2),
        fontSize: '15pt',
        color: "#6a7685",
    },
    divider: {
        paddingTop: "20px",
        paddingBottom: "20px",
    },
    buttonContainer: {
        marginTop: theme.spacing(2), // Adjust the spacing as needed
    },
    dialog: {
        
        paddingBottom: 20,
    },
    background: {
        background: defaultBlue,
    },
}));

export const defaultBlue = "#5b92e5";
export const defaultGreen = "#3cc016";
